import React from 'react';
import PropTypes from 'prop-types';
import './Loder.css';

const Loder = () => {

 

  return (

    <>
    <center className="mt-5">
      <div className="Category"> 
      
      <div class="spinner-border" role="status">
      
</div>

       </div>
       </center>
      </>
  );
}

Loder.propTypes = {};

Loder.defaultProps = {};

export default Loder;
