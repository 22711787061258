import React from 'react';
import PropTypes from 'prop-types';
import './Footer.css';

const Footer = () => (
  <div className="Footer">

  

  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
